import {useMemo} from 'react'
import {useSuspenseQuery} from '@apollo/client'
import {GetOutOfStockProductsInListQuery, GetOutOfStockProductsInListQueryVariables, GetProductsShowcaseProductsByIds_CachedQuery, GetProductsShowcaseProductsByIds_CachedQueryVariables, GetWebsiteMenuInformation_CachedQuery, GetWebsiteMenuInformation_CachedQueryVariables} from '@data/__generated__/types.main'
import {productsShowcaseQuery} from '@data/queries/components/products.main'
import {getOutOfStockProductsInListQuery} from '@data/queries/stock/getOutOfStockProductsInList.main'
import {getWebsiteMenuInformationQuery} from '@data/queries/website/websiteMenuInformation.main'
import usePreferenceStoreId from '@hooks/usePreferencesStoreId.main'
import useInitialData from '@page-components/Layout/useInitialData'
import uniq from 'lodash/uniq'

export default function useSetOutOfStockProducts({productsIds}) {
  const initialData = useInitialData()
  const {storeId = ''} = usePreferenceStoreId(initialData.website._id)
  const {data: {products}} = useSuspenseQuery<GetProductsShowcaseProductsByIds_CachedQuery, GetProductsShowcaseProductsByIds_CachedQueryVariables>(
    productsShowcaseQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      productsIds,
      websiteId: initialData.website._id,
      menuId: initialData.website.defaultMenuId
    },
  })

  const {data: outStockProductsQuery} = useSuspenseQuery<GetOutOfStockProductsInListQuery, GetOutOfStockProductsInListQueryVariables>(getOutOfStockProductsInListQuery, {
    variables: {
      websiteId: initialData.website._id,
      productsIds: uniq(productsIds),
      storeId: storeId,
      deliverAt: null
    },
    skip: productsIds.length === 0,
    fetchPolicy: 'cache-first'
  })

  const {data: {website}} = useSuspenseQuery<GetWebsiteMenuInformation_CachedQuery, GetWebsiteMenuInformation_CachedQueryVariables>(getWebsiteMenuInformationQuery, {
    variables: {
      websiteId: initialData.website._id
    },
    fetchPolicy: 'cache-first'
  })

  const hideNotAvailableProducts = website?.hideNotAvailableProducts

  const loading = !products

  const memoizedProducts = useMemo(() => {
    if (!products) return []
    const {outOfStockIds = []} = outStockProductsQuery || {}
    const productList = products.filter(product => {
      if (hideNotAvailableProducts && outOfStockIds.includes(product._id)) return false
      if (!product.availabilityAt) return false
      if (product.availabilityAt.notAvailableMessage) return true
      if (!product.availabilityAt.available) return false

      return true
    })

    return productList
  }, [products, outStockProductsQuery, hideNotAvailableProducts])

  return {products: memoizedProducts, loading}
}

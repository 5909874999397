import React from 'react'
import Loading from '@components/SuspenseLoading/Loading'
import Container from '@packages/justo-parts/lib/components/Container'

import Product from './Product'
import useOutOfStockProducts from './useOutOfStockProducts'

import styles from './styles.module.css'

export default function ProductShowcase(props: {
  productsIds: string[]
  title: string
}) {
  const {title} = props

  const {products, loading} = useOutOfStockProducts(props)

  if (loading || !products ) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <Container>
        {title ? (
          <div className={styles.header}>
            {title ? <h1 className={styles.title}>{title}</h1> : null}
          </div>
        ) : null}
        <div className={styles.products}>
          {products.map(product => (
            <Product key={product._id} product={product} />
          ))}
        </div>
      </Container>
    </div>
  )
}

import registerLocales from '@i18n/formatNumber/registerLocales'
import numeral from 'numeral'

import currencyCodes from './currencyCodes'
import moneyFormats from './moneyFormats'

export default function formatNumber(number, format, locale?, removeSymbolPreffix?) {
  locale = locale || global.currentLocale || 'es_CL'
  registerLocales(locale ? [locale] : [])

  let numeralFormat = format
  if (format === 'money' || format === 'moneyCode') {
    numeralFormat = moneyFormats[locale] || moneyFormats.es_CL
  }

  if (numeralFormat === '0,0%') {
    numeralFormat = '0,0.[00]%'
  }

  const prevLocale = numeral.options.currentLocale
  if (prevLocale !== locale) {
    numeral.locale(locale)
  }

  const numeralFormatCurrency = removeSymbolPreffix ? numeralFormat.replace('$', '') : numeralFormat
  const result = numeral(number).format(numeralFormatCurrency)

  if (prevLocale !== locale) {
    numeral.locale(prevLocale)
  }

  if (format === 'moneyCode') {
    return `${result} ${currencyCodes[locale]}`
  } else {
    return result
  }
}

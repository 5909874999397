import {useSuspenseQuery} from '@apollo/client'
import layoutFragment from '@data/fragments/website/layoutFragment.main'
import useWebsiteId from '@hooks/useWebsiteId'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'
import React, {ReactNode} from 'react'

import {
  getWebsiteMenuInformation_cached,
  getWebsiteMenuInformation_cachedVariables,
} from './__generated__/getWebsiteMenuInformation_cached'

export const getWebsiteMenuInformationQuery = gql`
  query getWebsiteMenuInformation_cached($websiteId: ID) {
    website(websiteId: $websiteId) {
      _id
      name
      description
      deliveryTypes
      isDeactivatedByDebt
      minimumOrderPrice
      menuLayout
      productsLayout
      headerLayout
      defaultMenuId
      isMultiBrand
      showStoreName
      activeGiftComponent
      hideNotAvailableProducts
      businessType
      categoryLayoutForMultiBrand
      categories {
        _id
        name
      }
      ...websiteLayout
    }

    design: sectionConfiguration(section: "design", websiteId: $websiteId) {
      _id
      design {
        productCardDesign
      }
    }
  }
  ${layoutFragment}
`

const WebsiteMenuInformationContext = React.createContext<getWebsiteMenuInformation_cached | null>(
  null,
)

export const WebsiteMenuInformationProvider = ({
  websiteMenuInfo,
  children,
}: {
  websiteMenuInfo: getWebsiteMenuInformation_cached
  children: ReactNode
}) => {
  return (
    <WebsiteMenuInformationContext.Provider value={websiteMenuInfo}>
      {children}
    </WebsiteMenuInformationContext.Provider>
  )
}

export const useWebsiteMenuInformation = () => {
  const websiteMenuInfo = React.useContext(WebsiteMenuInformationContext)
  const websiteId = useWebsiteId()

  const {data} = useSuspenseQuery<
    getWebsiteMenuInformation_cached,
    getWebsiteMenuInformation_cachedVariables
  >(getWebsiteMenuInformationQuery, {
    variables: {
      websiteId,
    },
    fetchPolicy: 'cache-first',
    skip: !!websiteMenuInfo,
  })

  return websiteMenuInfo ?? data
}

export default async function getWebsiteMenuInformation(websiteId?: string) {
  if (!websiteId) {
    return {website: null, design: null}
  }

  const {data} = await getClient().query<
    getWebsiteMenuInformation_cached,
    getWebsiteMenuInformation_cachedVariables
  >({
    query: getWebsiteMenuInformationQuery,
    variables: {
      websiteId,
    },
  })

  return data
}

export default {
  es_CL: '$0,0.[00]',
  es_MX: '$0,0.00',
  es_PE: '$0,0.00',
  es_CO: '$0,0.[00]',
  en_US: '$0,0.00',
  es_EC: '$0,0.00',
  es_CR: '$0,0.[00]',
  es_AR: '$0,0.[00]'
}

import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import gql from 'graphql-tag'

export const preferencesStoreIdQuery = gql`
  query getPreferencesStoreId($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      store {
        _id
      }
    }
  }
`

export default function usePreferenceStoreId(websiteId: string) {
  const userPreferences = useUserPreferences(websiteId)

  return {storeId: userPreferences.storeId}
}

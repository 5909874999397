import React from 'react'
import useInitialData from '@page-components/Layout/useInitialData'

import formatNumber from './index'

/**
 * Formats the number as a currency using the locale of the current website obtained through the initialData context.
 */
export default function FormatNumber({
  value,
  format = 'money',
  locale: localeParam,
  render,
  removeSymbolPreffix = false
}: {
  value: number
  format?: 'money' | 'moneyCode' | string
  locale?: string
  removeSymbolPreffix?: boolean
  render?(number: string): JSX.Element
}): JSX.Element {
  const initialData = useInitialData()
  const locale = localeParam ?? initialData.website.country.locale
  const number: string = formatNumber(value, format, locale, removeSymbolPreffix)

  if (render) {
    return render(number)
  }

  return <>{number}</>
}

import FormatNumber from '@i18n/formatNumber/Component'
import Link from 'next/link'
import {MdArrowForward as ArrowIcon} from 'react-icons/md'
import slugify from 'slugify'

import classnames from '@helpers/misc/classnames'
import Image from 'next/image'
import styles from './styles.module.css'

export default function ProductShowcase(props) {
  const {product} = props
  const slug = slugify(product.name, {lower: true, strict: true})
  const link = `/pedir/${product._id}/${slug}`
  if (!product.availabilityAt) return null
  const offer = product.availabilityAt.basePrice > product.availabilityAt.finalPrice

  // TODO: Use Next image instead. NOTE: since the width and height is unkown, we need to use layout=fill.
  // The problem with this is that we can't keep the image height to 100% and the width to be cropped.
  return (
    <div className={styles.container}>
      {product.image ? (
        <div className={styles.imageContainer}>
          <Image
            width={300}
            height={300}
            unoptimized={true}
            priority={true}
            className={classnames(styles.image, '!object-cover')}
            src={product.image.url}
            alt={product.altImg || product.name}
            title={product.titleImg || product.name}
          />
        </div>
      ) : null}
      <div className={styles.content}>
        <div className={styles.name}>{product.name}</div>
        <div className={styles.description}>{product.description}</div>
        <div className={styles.divider} />
        {offer ? (
          <div className={styles.offerPrice}>
            <FormatNumber value={product.availabilityAt.basePrice} />
          </div>
        ) : null}
        {product.availabilityAt?.finalPrice ? (
          <div className={styles.price}>
            <FormatNumber value={product.availabilityAt.finalPrice} />
          </div>
        ) : null}
      </div>
      <Link href={link} prefetch={false} className={styles.link}>
        Ver producto
        <ArrowIcon style={{top: -1, position: 'relative'}} />
      </Link>
    </div>
  )
}
